<template>
    <div class="cs-block cs-image-block" :class="styleClass">
        <div class="cs-block-base">
            <div class="container">
                <h2 v-if="data.title" class="cs-title">{{ data.title }}</h2>
                <h3 v-if="data.subTitle" class="cs-sub-title">{{data.subTitle}}</h3>
                <span v-if="data.text" class="cs-text" v-html="data.text"></span>
                <div class="image">
                    <img :src="data.image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "ImageBlock",
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {}
	},
	computed: computed('ImageBlock')
}
</script>

<style lang="scss" scoped>
@import "../styles/_mixins.scss";

.cs-image-block {
    .cs-block-base {
        position: relative;
    }
    .image{
        position: relative;
        img{
            object-fit: cover;
            width: 100%;
            height: 350px;
            @media(max-width: 600px) {
                height: 250px;
            }
        }
    }
}
</style>
